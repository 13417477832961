export const mapLexItems = data =>
  data.map(item => ({
    id: item.pk,
    title: item.title,
    slug: item.slug,
    stage: item.stage_primary,
    stageSecondary: item.stage_secondary,
    date: new Date(item.last_change),
    open: false,
  }));
