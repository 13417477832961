export const legislationFilters = [
  {
    value: '',
    label: 'tytuł',
    name: 'title',
    type: 'text',
    highlightKeyword: 'title_keyword',
  },
  {
    value: '',
    label: 'tekst przepisów',
    name: 'text',
    type: 'text',
    keys: ['full_text_2d', 'full_text_w', 'full_text_m', 'full_text_hy', 'full_text'],
    splitRequests: true,
    highlight: true,
  },
  {
    value: '',
    label: 'zmieniane ustawy',
    name: 'amend',
    type: 'text',
    keys: ['amended_short', 'amended_full'],
    splitRequests: true,
    highlightKeyword: 'amended_keyword',
  },
  {
    value: '',
    label: 'uchylane ustawy',
    name: 'derogated',
    type: 'text',
    highlightKeyword: 'derogated_keyword',
  },
  {
    value: '',
    label: 'wdrażane akty UE',
    name: 'eu_related',
    type: 'text',
    highlightKeyword: 'eu_keyword',
  },
  { value: '', label: 'etap', name: 'stage', type: 'text' },
  {
    value: [],
    label: 'status',
    name: 'status',
    type: 'select',
    options: [
      { value: 'A', label: 'prace w toku' },
      { value: 'P', label: 'ogłoszona' },
      { value: 'F', label: 'obowiązuje' },
      { value: 'R', label: 'archiwalny' },
    ],
  },
  {
    value: [],
    label: 'inicjatywa ustawodawcza',
    name: 'type_initiative',
    type: 'select',
    options: [
      { value: 'D', label: 'poselski' },
      { value: 'K', label: 'komisyjny' },
      { value: 'R', label: 'rządowy' },
      { value: 'P', label: 'prezydencki' },
      { value: 'S', label: 'senacki' },
      { value: 'O', label: 'obywatelski' },
    ],
  },
  {
    value: [],
    label: 'minister',
    name: 'minister',
    type: 'select',
    options: [],
    optionsLoading: true,
  },
  {
    value: '',
    label: 'osoba odpowiedzialna',
    name: 'person_responsible',
    type: 'text',
  },
  {
    value: '',
    label: 'opis projektu',
    name: 'descriptions_filter',
    type: 'text',
    highlightKeyword: 'description_keyword',
  },
  { value: '', label: 'numer kadencji', name: 'term', type: 'text' },
  {
    value: '',
    label: 'numer w wykazie',
    name: 'kprm_register',
    type: 'text',
  },
  {
    value: '',
    label: 'druk sejmowy',
    name: 'sejm_printing',
    type: 'text',
  },
  {
    value: '',
    label: 'druk senacki',
    name: 'senat_printing',
    type: 'text',
  },
];
