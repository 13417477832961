<template>
  <div :class="{ narrow }" v-escape="closeItems">
    <div class="table-header tablet-desktop mb-4">
      <div class="title">
        <span>tytuł ustawy lub projektu</span>
        <span>ostatnia zmiana</span>
      </div>
      <div class="stage">etap</div>
      <div class="stageSecondary">status projektu</div>
    </div>
    <div v-for="(item, index) in items" :key="item.id" :id="item.slug">
      <legislation-item
        @toggleOpen="event => toggleItem(index, event)"
        @loaded="fixScrollPosition(item.id)"
        :searchHighlights="searchHighlights"
        :open="item.open"
        :listItem="item"
        :narrow="narrow"
        ref="item"
      />
    </div>
  </div>
</template>

<script>
import LegislationItem from '../LegislationItem/LegislationItem';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  components: {
    LegislationItem,
  },
  props: {
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    items: Array,
    narrow: Boolean,
    ownApi: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      api: {
        moduleId: 'pk',
        list: 'lex',
        modules: 'module_lex_header',
        favoriteHated: 'favorite_and_hated_lex_header',
        favoriteHatedResponse: 'favorite_and_hateful_leges_list',
        bookmarks: 'bookmarks/fetchLegislation',
        listModules: 'custom_multi_query',
      },
    };
  },
  computed: {
    ...mapGetters({
      isModalActive: 'isModalActive',
      isProjectOpen: 'isProjectOpen',
      isAccordionOpen: 'isAccordionOpen',
    }),
    currentProject() {
      return this.items.findIndex(item => item.open);
    },
  },
  watch: {
    isProjectOpen(v) {
      if (v === false && this.currentProject >= 0) {
        this.closeItems();
      }
    },
    currentProject(v) {
      if (v >= 0) {
        this.openProject();
      } else {
        this.closeProject();
      }
    },
    narrow(value) {
      if (value) {
        this.items.forEach(item => {
          item.open = false;
        });
      }
    },
  },
  created() {
    if (this.ownApi) {
      this.setApi(this.api);
      this.getFavoriteHated();
      this.setModulesForCurrentList();
    }
  },
  destroyed() {
    if (this.ownApi) {
      this.clearListState();
    }
  },
  methods: {
    ...mapMutations({
      openProject: 'openProject',
      closeProject: 'closeProject',
    }),
    ...mapMutations('list', ['setApi', 'clearListState']),
    ...mapActions('list', ['getFavoriteHated', 'setModulesForCurrentList']),
    closeItems() {
      if (this.isModalActive || this.isAccordionOpen.length > 0) {
        return;
      }
      this.items.forEach(item => {
        item.open = false;
      });
    },
    toggleItem(index, event = null) {
      if (event && (event.ctrlKey || event.button === 1)) {
        if (event.type === 'mousedown') {
          event.preventDefault();
        } else {
          const route = this.$router.resolve({
            name: 'legislation-item',
            params: { slug: this.items[index].slug },
          });
          window.open(window.location.origin + route.href, '_blank').focus();
        }
      } else if (event.type === 'mouseup') {
        this.items.forEach((item, itemIndex) => {
          if (itemIndex === index) {
            item.open = !item.open;
            this.$emit('toggle-item', item.open);
          } else {
            item.open = false;
          }
        });
      }
    },
    fixScrollPosition(index) {
      setTimeout(() => {
        const scroll = this.$store.getters['user/isLoggedIn'] ? 110 : 145;
        const element = this.$refs.item.find(
          item => item.listItem.id === index
        );
        const fromTop = element.$el.getBoundingClientRect().top;
        window.scrollTo(0, window.scrollY - (scroll - fromTop));
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-header {
  display: flex;
  padding: 1rem;
  padding-right: 10rem;
  border-bottom: 2px solid $gray-5;
  color: $gray-2;
  position: relative;
  z-index: 10;
}

.title {
  width: 71%;
  display: flex;
  justify-content: space-between;
}
.stage {
  width: 13%;
  text-align: center;
}
.stageSecondary {
  width: 16%;
}
.narrow {
  .title {
    width: 60%;
  }
  .stageSecondary {
    width: 27%;
  }
}
</style>
