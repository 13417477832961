<template>
  <item
    :config="config"
    :open="open"
    :searchHighlights="searchHighlights"
    :listItem="listItem"
    :narrow="narrow"
    v-on="$listeners"
    class="legislation-item"
  >
    <div v-if="listItem" class="item__closed-stage tablet-desktop">
      {{ listItem.stage }}
    </div>
    <div v-if="listItem" class="item__closed-stageSecondary">
      {{ listItem.stageSecondary }}
    </div>
  </item>
</template>

<script>
import Item from '../../components/Item/Item';
import { legislationItemPrepare } from './util/legislationItemPrepare';
import { mapActions, mapMutations } from 'vuex';

export default {
  components: { Item },
  metaInfo() {
    return {
      link: [
        {
          rel: 'canonical',
          href: `${window.location.origin}${this.$route.fullPath}`,
        },
      ],
    };
  },
  props: {
    open: Boolean,
    searchHighlights: {
      query: String,
      keywords: Array,
    },
    listItem: Object,
    narrow: Boolean,
  },
  data() {
    return {
      apiModules: {
        moduleId: 'pk',
        list: 'lex',
        modules: 'module_lex_header',
        favoriteHated: 'favorite_and_hated_lex_header',
        favoriteHatedResponse: 'favorite_and_hateful_leges_list',
        bookmarks: 'bookmarks/fetchLegislation',
        listModules: 'custom_multi_query',
      },
      config: {
        routeName: 'legislation-item',
        routeList: 'legislation',
        route404: 'legislation',
        prepareData: legislationItemPrepare,
        api: {
          body: 'lex_body',
          highlights: 'lex/load_highlights',
          monitored: 'lex/load_provisions_with_favorite_flag',
          additional: 'lex_provisions_and_versions',
          textVersions: {
            lastVersion: 'v1/lex_last_version_button',
            version: 'v1/lex_version',
          },
          descriptions: 'v1/lex_other_descriptions',
        },
        bookmark: {
          type: 'legislation',
          fetch: 'fetchLegislation',
          toggle: 'toggleLegislation',
        },
        labels: {
          linksTitle: 'Projekt na stronie:',
          linksDoneTitle: 'Ustawa na stronie:',
        },
      },
    };
  },
  created() {
    if (!this.listItem) {
      this.setApi(this.apiModules);
      this.getFavoriteHated();
      this.setModulesForCurrentList();
    }
  },
  destroyed() {
    if (!this.listItem) {
      this.clearListState();
    }
  },
  methods: {
    ...mapMutations('list', ['setApi', 'clearListState']),
    ...mapActions('list', ['getFavoriteHated', 'setModulesForCurrentList']),
  },
};
</script>

<style lang="scss">
@import './LegislationItem';
</style>
