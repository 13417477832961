import { createPrepareItem } from '../../../util/item/createPrepareItem';

export const legislationItemPrepare = createPrepareItem('lex_summary', [
  {
    name: 'Ustawy nowelizowane',
    listKey: 'amended_list',
    highlightKey: 'amended_leges_with_keywords_in_title',
    highlightSearchKey: 'amended_leges_with_search_keywords_in_title',
    valueKey: 'amended_provisions',
    highlightTooltipName: {
      plural: 'ustaw objętych',
      singular: 'Ustawa jest objęta',
    },
  },
  {
    name: 'Ustawy uchylane',
    listKey: 'derogated_list',
    highlightKey: 'derogated_leges_with_keywords_in_title',
    highlightSearchKey: 'derogated_leges_with_search_keywords_in_title',
    valueKey: 'derogated_provisions',
    highlightTooltipName: {
      plural: 'ustaw objętych',
      singular: 'Ustawa jest objęta',
    },
  },
  {
    name: 'Wdrażane akty europejskie',
    listKey: 'eu_related_list',
    highlightKey: 'eu_related_with_keywords_in_title',
    highlightSearchKey: 'eu_related_with_search_keywords_in_title',
    valueKey: 'eu_related_provisions',
    highlightTooltipName: {
      plural: 'aktów objętych',
      singular: 'Akt jest objęty',
    },
  },
]);
